export default attributes => {
    return Object.entries(attributes).reduce((acc, curr) => {
        const [key, value] = curr;
        if (!key.includes('question')) {
            // Skip if not a question
            return acc;
        }
        if (!value) {
            // Skip this iteration of no question
            return acc;
        }

        // Get the question number to find the answer connected to it
        const currentQuestionNumber = key[key.length - 1];
        const answer = attributes[`answer_${currentQuestionNumber}`];
        acc.push({ question: value, answer });
        return acc;
    }, []);
};
