import React from 'react';
import PropTypes from 'prop-types';
import FAQItem from './components/faq-item';
import { getFaqFromAttributes } from './helpers';
import './style.styl';
import { FAQPage } from '@24hr/react-structured-data';

const FAQClusterPage = ({ faq, title }) => {
    return (
        <div>
            <h1 className="faq-cluster-page--heading">{title}</h1>
            {faq.map((f, index) => {
                return (
                    <FAQItem
                        key={index}
                        answer={f.answer}
                        question={f.question}
                    />
                );
            })}
            <FAQPage questions={faq} />
        </div> 
    );
};

FAQClusterPage.vcConvertToProps = atts => {
    return {
        faq: getFaqFromAttributes(atts),
        title: atts.heading_text,
    };
};

FAQClusterPage.parseProps = atts => {
    return {
        faq: atts.questions,
        title: atts.labels.headingText,
    };
};

FAQClusterPage.propTypes = {
    faq: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string.isRequired,
            answer: PropTypes.string.isRequired,
        })
    ),
    title: PropTypes.string,
};

export default FAQClusterPage;
