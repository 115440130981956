import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../icons';
import './style.styl';
const FAQItem = ({ question, answer }) => {
    const [open, setOpen] = useState(false);

    const toggle = () => setOpen(!open);
    const panelRef = useRef(null);
    if (!question || !answer) {
        // Do not render if no question or answer!
        return null;
    }
    return (
        <div className="faq-cluster-page--faq-item">
            <button className="faq-cluster-page--question" onClick={toggle}>
                {question}
                <span
                    className={`faq-cluster-page--icon ${open ? 'open' : ''}`}
                >
                    <Icon name="plus" color="#000" />
                </span>
            </button>
            <div
                className={`faq-cluster-page--answer-body ${
                    open ? 'open' : ''
                }`}
                ref={panelRef}
                style={{ maxHeight: open ? panelRef.current.scrollHeight : 0 }}
            >
                <p>{answer}</p>
            </div>
            <hr className="faq-cluster-page--line" />
        </div>
    );
};

FAQItem.propTypes = {
    answer: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
};

export default FAQItem;
